import axios from "axios";
import { InventoryItemStoredImage } from "../external/inventory-component/types";

export interface ImageScrapeURLResult {
  clientName: string;
  urls: string[];
  pageTitle: string;
  imagesFoundInScrape: number;
}

export const GetImagesForUrl = async (
  url: string,
  selectedClient: string,
  authToken: string
) => {
  const getImagesUrl = process.env.REACT_APP_GET_IMAGES_URL as string;
  const clientRequestResult = await axios.get<ImageScrapeURLResult>(
    getImagesUrl,
    {
      params: {
        url,
        client: selectedClient,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return clientRequestResult.data;
};

interface GetPresignedUrlBody {
  filename: string;
  prefix?: string;
}

export interface GetPresignedUrlResult {
  uploadUrl: string;
  hostedFileUrl: string;
}

export const GetPresignedUrlForImageUpload = async (
  filename: string,
  authToken: string,
  prefix?: string
) => {
  const getPresignedUrl = process.env
    .REACT_APP_GET_PRESIGNED_URL_IMAGE_UPLOAD as string;

  const requestBody: GetPresignedUrlBody = {
    filename,
  };

  if (prefix) {
    requestBody.prefix = prefix;
  }

  const requestResult = await axios.post<GetPresignedUrlResult>(
    getPresignedUrl,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  return requestResult.data;
};

export type GenerateGenericUploadImageUrlRequest = Pick<
  InventoryItemStoredImage,
  "name" | "comment" | "imageSource"
>;

/**
 * The server has stricter values.
 */
export type EntityPrefix = string;

export interface GenerateGenericUploadImageUrlEventBody {
  entityPrefix: EntityPrefix;
  entityId: string;
  images: GenerateGenericUploadImageUrlRequest[];
}

export interface GenerateGenericPreSignedURLResponseItem {
  imageName: string;
  imageKey: string;
  url: string;
}

export const GetPresignedUrlForGenericEntityImagesUpload = async (
  entityType: EntityPrefix,
  entityId: string,
  images: GenerateGenericUploadImageUrlRequest[]
) => {
  const getPresignedUrl = process.env
    .REACT_APP_GENERIC_IMAGE_URL_GET_PRESIGNED_URL as string;

  const requestBody: GenerateGenericUploadImageUrlEventBody = {
    entityId,
    entityPrefix: entityType,
    images,
  };

  const requestResult = await axios.post<{
    presignedUrls: GenerateGenericPreSignedURLResponseItem[];
  }>(getPresignedUrl, requestBody, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return requestResult.data;
};

export const GetEntityListFromGenericImageUploads = async (
  entityType: EntityPrefix
) => {
  const getEntityListUrl = process.env
    .REACT_APP_GENERIC_IMAGE_URL_GET_ENTITY_LIST as string;

  const url = getEntityListUrl.replaceAll(":entity:", entityType);
  const requestResult = await axios.get<string[]>(url);
  return requestResult.data;
};

export interface UploadedImageResult {
  key: string;
  size?: number;
  contentType?: string;
  metadata?: Record<string, string>;
  url: string;
}

export const GetGenericImagesFromEntityWithId = async (
  entityType: EntityPrefix,
  entityId: string
) => {
  const getEntityListUrl = process.env
    .REACT_APP_GENERIC_IMAGE_URL_GET_IMAGES_BY_ENTITY as string;

  const url = getEntityListUrl
    .replaceAll(":entity:", entityType)
    .replaceAll(":entityId:", entityId);

  const requestResult = await axios.get<InventoryItemStoredImage[]>(url);
  return requestResult.data;
};

export const UploadFileViaPresignedUrl = async (
  presignedUrl: string,
  requestBody: any,
  contentType: string
) => {
  const uploadResult = await axios.put(presignedUrl, requestBody, {
    headers: {
      "Content-Type": contentType,
    },
  });
  return uploadResult;
};
