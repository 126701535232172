import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import React, { useState, useEffect, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { EbayCategory } from "../../workers/ebay-categories";
import { UserAuthenticatedContext } from "../profile";
import { useDebounceValue } from "usehooks-ts";

const createEbayCategoryWorker = createWorkerFactory(
  () => import("../../workers/ebay-categories")
);

export interface eBaySearchComponentProps {
  filters?: boolean;
  isLoading?: boolean;
  isSaving?: boolean;
  viewOnly?: boolean;
  childToParent: (value?: string) => void;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}

const EbaySearchComponent = ({
  filters = false,
  isSaving,
  viewOnly,
  isLoading,
  childToParent,
  searchTerm,
  setSearchTerm,
}: eBaySearchComponentProps) => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [userToken, setUserToken] = useState<string>();

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  const [results, setResults] = useState<EbayCategory[]>([]);

  const ebayCategoryWorker = useWorker(createEbayCategoryWorker);

  // Debounce search so that requests aren't sent on every keystroke
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useDebounceValue(
    searchTerm,
    500
  );

  useEffect(() => {
    setDebouncedSearchTerm(searchTerm);
  }, [searchTerm, setDebouncedSearchTerm]);

  useEffect(() => {
    const fetchEbayCategories = async () => {
      if (debouncedSearchTerm && userToken) {
        const ebayCategorySearchResults =
          await ebayCategoryWorker.searchEbayCategories(
            debouncedSearchTerm,
            userToken
          );
        setResults(ebayCategorySearchResults.data);
      } else {
        setResults([]);
      }
    };

    fetchEbayCategories();
  }, [debouncedSearchTerm, userToken, ebayCategoryWorker]);

  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    childToParent(event.target.value);
  };

  return (
    <Form.Group>
      <Row>
        <Col xs={filters ? 12 : 6}>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="Search for a category"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              disabled={isSaving || viewOnly || isLoading}
            />
          </InputGroup>
        </Col>
        <Col xs={filters ? 12 : 6}>
          <InputGroup className="mb-3">
            <Form.Select
              onChange={handleDropdownChange}
              name="ebayCategorySearchResults"
              disabled={isSaving || viewOnly || isLoading || !results.length}
            >
              <option>Select a category</option>
              {results.map((category) => (
                <option key={category.id} value={JSON.stringify(category)}>
                  {category.id} - {category.name}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default EbaySearchComponent;
