import React, { useState, useEffect, useCallback } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import InventoryImagesComponent from "../components/inventory/inventory-images";
import ImageUploadComponent, {
  FileWithComment,
} from "../components/inventory/image-upload-component";
import {
  GenerateGenericUploadImageUrlRequest,
  GetEntityListFromGenericImageUploads,
  GetGenericImagesFromEntityWithId,
  GetPresignedUrlForGenericEntityImagesUpload,
  UploadFileViaPresignedUrl,
} from "../workers/images";
import { InventoryItemStoredImage } from "../external/inventory-component/types";
import { Image } from "react-bootstrap-icons";
import { InputGroup } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

const prefixOptions = [
  { value: "pm", display: "PM Item" },
  { value: "load", display: "Load" },
  { value: "pallet", display: "Pallet" },
  { value: "product", display: "Product" },
  { value: "location", display: "Location" },
];

const ITEMS_PER_PAGE = 25;

const GeneralUploadTool: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [entityId, setEntityId] = useState("");
  const [items, setItems] = useState<{ [key: string]: string[] }>({});
  const [images, setImages] = useState<InventoryItemStoredImage[]>([]);
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [files, setFiles] = useState<FileWithComment[]>([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [currentPage, setCurrentPage] = useState(prefixOptions[0].value);
  const [activeEntityId, setActiveEntityId] = useState("");
  const [showItemsModal, setShowItemsModal] = useState(false);

  const handleFetchItems = async (prefix: string) => {
    setLoading((prev) => ({ ...prev, [prefix]: true }));
    try {
      const result = await GetEntityListFromGenericImageUploads(prefix);
      setItems((prev) => ({ ...prev, [prefix]: result }));
    } catch (error) {
      console.error("Error fetching items", error);
    } finally {
      setLoading((prev) => ({ ...prev, [prefix]: false }));
    }
  };

  const handleFetchImages = useCallback(
    async (item: string) => {
      setLoading((prev) => ({ ...prev, images: true }));
      try {
        const result = await GetGenericImagesFromEntityWithId(
          currentPage,
          item
        );
        setImages(result);
        setActiveEntityId(item); // Set active entity ID after fetching images
        setSearchParams({
          page: currentPage,
          entityId: item,
        });
      } catch (error) {
        console.error("Error fetching images", error);
      } finally {
        setLoading((prev) => ({ ...prev, images: false }));
      }
    },
    [currentPage, setSearchParams]
  );

  useEffect(() => {
    const entityIdFromUrl = searchParams.get("entityId");
    if (entityIdFromUrl) {
      setActiveEntityId(entityIdFromUrl);
      handleFetchImages(entityIdFromUrl);
    }
  }, [handleFetchImages, searchParams]);

  const handleUploadImages = async (acceptedFiles: FileWithComment[]) => {
    setLoading((prev) => ({ ...prev, images: true }));
    try {
      const imagesForUpload = acceptedFiles.map(
        (file): GenerateGenericUploadImageUrlRequest => ({
          name: file.file.name,
          comment: file.comment,
          imageSource: "upload",
        })
      );

      const presignedUrlResponse =
        await GetPresignedUrlForGenericEntityImagesUpload(
          currentPage,
          entityId,
          imagesForUpload
        );
      const uploadPromises = presignedUrlResponse.presignedUrls.map(
        async (response, index) => {
          await UploadFileViaPresignedUrl(
            response.url,
            acceptedFiles[index].file,
            acceptedFiles[index].file.type
          );
        }
      );

      await Promise.all(uploadPromises);
      await handleFetchImages(entityId);
    } catch (error) {
      console.error("Error uploading images", error);
    } finally {
      setLoading((prev) => ({ ...prev, images: false }));
    }
  };

  const handleRemoveFile = (file: FileWithComment) => {
    setFiles(files.filter((f) => f !== file));
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    return () =>
      files.forEach((file) => URL.revokeObjectURL((file as any).preview));
  }, [files]);

  // On load fetch items for the first prefix
  useEffect(() => {
    handleFetchItems(prefixOptions[0].value);
  }, []);

  const paginatedItems = items[currentPage]?.slice(
    prefixOptions.findIndex((option) => option.value === currentPage) *
      ITEMS_PER_PAGE,
    (prefixOptions.findIndex((option) => option.value === currentPage) + 1) *
      ITEMS_PER_PAGE
  );

  return (
    <Container>
      <h1>Image Upload Tool</h1>
      <Tabs
        defaultActiveKey={prefixOptions[0].value}
        id="image-upload-tool-tabs"
        activeKey={currentPage}
        onSelect={(k) => {
          setCurrentPage(k || prefixOptions[0].value);
          setSearchParams({
            page: k || prefixOptions[0].value,
            entityId: "",
          });
          setEntityId("");
          setActiveEntityId("");
          handleFetchItems(k || prefixOptions[0].value);
        }}
      >
        {prefixOptions.map((option) => (
          <Tab
            eventKey={option.value}
            title={option.display}
            key={option.value}
          >
            <Container className="mt-3">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFetchImages(entityId);
                }}
              >
                <Form.Group controlId="entityIdInput">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder={`Enter ${option.display} ID`}
                      value={entityId}
                      style={{ maxWidth: "300px" }}
                      onChange={(e) => setEntityId(e.target.value)}
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loading.images}
                    >
                      {loading.images ? (
                        <span>
                          <Spinner
                            as={"span"}
                            animation="border"
                            role="status"
                            aria-hidden="true"
                            size="sm"
                            style={{ marginRight: "0.5em" }}
                          />
                          Loading...
                        </span>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </InputGroup>
                </Form.Group>
              </Form>
              <Button
                variant="info"
                className="mt-2 w-100"
                onClick={() => setShowItemsModal(true)}
              >
                Show Existing Items List
              </Button>
              <hr />
              <Modal
                show={showItemsModal}
                onHide={() => setShowItemsModal(false)}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Existing items with images</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {loading[currentPage] ? (
                    <Spinner animation="border" />
                  ) : (
                    <>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Item ID</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedItems?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {prefixOptions.findIndex(
                                  (option) => option.value === currentPage
                                ) *
                                  ITEMS_PER_PAGE +
                                  index +
                                  1}
                              </td>
                              <td>
                                <pre>{item}</pre>
                              </td>
                              <td>
                                <Button
                                  variant="success"
                                  onClick={() => {
                                    handleFetchImages(item);
                                    setShowItemsModal(false);
                                  }}
                                >
                                  Show Images <Image />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </Modal.Body>
              </Modal>
              {activeEntityId && (
                <>
                  {images && images.length > 0 ? (
                    <h5>Showing images for {activeEntityId}</h5>
                  ) : (
                    <h5>No images stored for item {activeEntityId}</h5>
                  )}
                  <Button
                    variant="success"
                    onClick={() => setShowUploadModal(true)}
                  >
                    Upload Images
                  </Button>
                  <Modal
                    show={showUploadModal}
                    onHide={() => setShowUploadModal(false)}
                    size="lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Upload Images</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <ImageUploadComponent
                        onDrop={(acceptedFiles) => {
                          setFiles(acceptedFiles);
                        }}
                        onRemoveFile={handleRemoveFile}
                        maxFiles={25}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => setShowUploadModal(false)}
                        disabled={isUploadingImages}
                      >
                        Close
                      </Button>
                      <Button
                        variant="success"
                        onClick={async () => {
                          setIsUploadingImages(true);
                          await handleUploadImages(files);
                          setIsUploadingImages(false);
                          setShowUploadModal(false);
                        }}
                        disabled={isUploadingImages}
                      >
                        Save Images
                        {isUploadingImages ? (
                          <Spinner style={{ marginLeft: "0.5em" }} size="sm" />
                        ) : null}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {images && images.length > 0 ? (
                    <InventoryImagesComponent
                      images={images}
                      // deleteImageFunction={handleDeleteImage}
                      maxWidth="700px"
                    />
                  ) : null}
                </>
              )}
            </Container>
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default GeneralUploadTool;
