import React, { useCallback, useContext, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FormEvent, useState } from "react";
import { HourglassBottom, UpcScan } from "react-bootstrap-icons";
import InventoryItemComponent from "../components/inventory/inventory-item";
import { CatalogItem, InventoryItem } from "../external";
import { createWorkerFactory } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../components/profile";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/loading";
import CatalogItemPreviewComponent from "../components/gradingandrefurb/refurb-steps/catalog-item-preview";
import BarcodeScanner from "../components/barcode-scanner";
import { Result } from "@zxing/library";
import axios, { AxiosError } from "axios";
import Alert from "react-bootstrap/Alert";
import { useLocalStorage } from "usehooks-ts";
import { useSearchParams } from "react-router-dom";
import { useCircleDarkMode } from "../hooks/useCircleDarkMode";
import InventoryImagesComponent from "../components/inventory/inventory-images";
import ImageUploadComponent from "../components/inventory/image-upload-component";
import { GenerateUploadImageUrlImage } from "../workers/inventory";
import { Spinner } from "react-bootstrap";
import PrintUI from "../components/printing/print-ui";
import { CreateItemLabelHTML } from "../components/printing/generate-item-label-html";
import { InventoryItemWithPatchableFields } from "../external/inventory-component/types";

const createInventoryItemsWorker = createWorkerFactory(
  () => import("../workers/inventory")
);
const createCatalogWorker = createWorkerFactory(
  () => import("../workers/catalog")
);

type InventoryItemLookupHistoryItem = {
  id: string;
  timestamp: string;
};

const InventoryScanPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const inventoryWorker = createInventoryItemsWorker();
  const catalogWorker = createCatalogWorker();
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const inventoryItemContainerRef = useRef<HTMLDivElement>();

  const hasInitialValue = searchParams.get("itemId");

  const [inputValue, setInputValue] = useState<string>(
    searchParams.get("itemId") ?? ""
  );

  const [initiallyRetrieved, setInitiallyRetrieved] = useState<boolean>(false);

  const [loadingInventoryItem, setLoadingInventoryItem] =
    useState<boolean>(false);
  const [loadingCatalogItem, setLoadingCatalogItem] = useState<boolean>(false);
  const [showBarcodeScanner, setShowBarcodeScanner] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [inventoryItem, setInventoryItem] = useState<InventoryItem>();
  const [catalogItem, setCatalogItem] = useState<CatalogItem>();

  const [errorText, setErrorText] = useState<string>("");
  const darkMode = useCircleDarkMode();

  const [modalImageUploadShow, setModalImageUploadShow] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState<File[]>([]);
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [printLabelContentsHTML, setPrintLabelContents] = useState<string[]>();
  const [isPersisting, setIsPersisting] = useState(false);

  useEffect(() => {
    if (!inventoryItem || !catalogItem) {
      return;
    }

    if (!printLabelContentsHTML && inventoryItem && catalogItem) {
      let boxCount = catalogItem.boxCount ?? 1;
      if (
        catalogItem.dimensions?.boxes &&
        catalogItem.dimensions.boxes.length > 0
      ) {
        boxCount = catalogItem.dimensions?.boxes.length;
      }

      const createLabelHtmlProps = {
        inventoryId: inventoryItem.id,
        boxCount,
        pmid: inventoryItem.legacyPMID,
        comments: inventoryItem.comments,
        grade: "TBD",
        // grade: inventoryItem.grade,
        currentBoxNumber: 1,
        productListingTitle: catalogItem.name ?? "Unknown",
        sku: catalogItem.itemId,
        packedDateTime: new Date().toISOString(),
        packerName: "Unknown",
        palletRef: "Unknown",
      };

      Promise.all(
        Array.from({ length: boxCount }).map((_, i) =>
          CreateItemLabelHTML({
            data: {
              ...createLabelHtmlProps,
              currentBoxNumber: i + 1,
            },
          })
        )
      )
        .then((htmlArray) => {
          setPrintLabelContents(htmlArray);
        })
        .catch((e) => {
          console.error("Error generating print label HTML");
          console.error(e);
        });
    }
  }, [inventoryItem, catalogItem, printLabelContentsHTML]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!inputRef.current?.value) {
      console.error("No input value found");
      return;
    }
    retrieveAndRender(inputRef.current?.value);
  };

  const [inventoryItemLookupHistory, setInventoryItemLookupHistory] =
    useLocalStorage<InventoryItemLookupHistoryItem[]>(
      // Use hostname to not confuse dev with local deployments
      `cc-inventoryItemLookupHistory-${process.env.REACT_APP_CREATE_INVENTORY_ITEM_URL}`,
      []
    );

  // remove old history entries if they exist
  if (window.localStorage.getItem(`cc-inventoryItemLookupHistory`) !== null) {
    window.localStorage.removeItem(`cc-inventoryItemLookupHistory`);
  }
  if (
    window.localStorage.getItem(
      `cc-inventoryItemLookupHistory-${window.location.hostname}`
    ) !== null
  ) {
    window.localStorage.removeItem(
      `cc-inventoryItemLookupHistory-${window.location.hostname}`
    );
  }

  const retrieveAndRender = useCallback(
    (id: string) => {
      setLoadingInventoryItem(true);
      setLoadingCatalogItem(true);
      setErrorText("");
      setCatalogItem(undefined);
      setInventoryItem(undefined);

      if (!userAuthenticatedContext.token) {
        alert("No token found... are you logged in?");
        return;
      }

      inventoryWorker
        .getInventoryItem(id, userAuthenticatedContext.token)
        .then((item) => {
          console.debug("Retrieved item", item);
          const historyItem: InventoryItemLookupHistoryItem = {
            timestamp: new Date().toISOString(),
            id: item.id,
          };

          if (inventoryItemLookupHistory.map((i) => i.id).includes(item.id)) {
            const newHistory = inventoryItemLookupHistory.filter(
              (historyItem) => historyItem.id !== item.id
            );
            newHistory.push(historyItem);
            setInventoryItemLookupHistory(newHistory);
          } else {
            setInventoryItemLookupHistory([
              ...inventoryItemLookupHistory,
              historyItem,
            ]);
          }

          setInventoryItem(item);

          if (inputValue !== item.id) {
            setInputValue(item.id);
          }
          setSearchParams({ itemId: item.id });
          if (!item.catalogItemId) {
            setLoadingCatalogItem(false);
            return;
          }

          catalogWorker
            .getCatalogItem(userAuthenticatedContext.token!, item.catalogItemId)
            .then((catalogItem) => {
              setCatalogItem(catalogItem);
              if (
                inventoryItemContainerRef &&
                inventoryItemContainerRef.current
              ) {
                inventoryItemContainerRef.current.scrollIntoView();
              }
            })
            .catch((e) => {
              console.error(e);
            })
            .finally(() => {
              setLoadingCatalogItem(false);
            });
        })
        .catch((e) => {
          if (e instanceof AxiosError && e.isAxiosError) {
            if (e.response?.status === 404) {
              setErrorText(`Inventory item not found for ID '${inputValue}'`);
              setSearchParams({ itemId: "" });
            } else if (e.response?.status === 403) {
              setErrorText(
                "Authentication error: You do not have permission to access this resource."
              );
            }
          } else {
            alert("Unknown error");
            console.error(e);
          }
          setLoadingCatalogItem(false);
        })
        .finally(() => {
          setLoadingInventoryItem(false);
        });
    },
    [
      userAuthenticatedContext.token,
      inventoryWorker,
      inventoryItemLookupHistory,
      setSearchParams,
      inputValue,
      catalogWorker,
      setInventoryItemLookupHistory,
    ]
  );

  useEffect(() => {
    if (
      !initiallyRetrieved &&
      hasInitialValue &&
      inputValue &&
      userAuthenticatedContext &&
      userAuthenticatedContext.token
    ) {
      setInitiallyRetrieved(true);
      retrieveAndRender(inputValue);
    }
  }, [
    hasInitialValue,
    inputValue,
    initiallyRetrieved,
    retrieveAndRender,
    userAuthenticatedContext,
  ]);

  const onBarcodeDetected = useCallback(
    (barcode: Result) => {
      const v = barcode.getText();
      setInputValue(v);
      setShowBarcodeScanner(false);
      retrieveAndRender(v);
    },
    [retrieveAndRender]
  );

  const updateInventoryItem = (
    newInventoryItem: InventoryItemWithPatchableFields
  ): Promise<void> => {
    if (!inventoryItem) {
      return Promise.resolve();
    }
    setIsPersisting(true);
    return new Promise((resolve, reject) => {
      inventoryWorker
        .patchInventoryItem(
          inventoryItem.id,
          {
            ...newInventoryItem,
          },
          userAuthenticatedContext.token!
        )
        .then((updatedItem) => {
          // Do something else here!
          setInventoryItem(updatedItem);
          setIsPersisting(false);
          resolve();
        })
        .catch((e) => {
          console.error(e);
          setIsPersisting(false);
          reject(e);
        });
    });
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Container style={{ maxWidth: "98vw" }}>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <Form.Control
                style={{
                  fontSize: "1.5em",
                  fontFamily: "Menlo, monospace, sans-serif",
                  textAlign: "center",
                  maxWidth: "800px",
                }}
                autoFocus
                type="text"
                name="type"
                placeholder="Enter or Scan Inventory ID"
                ref={inputRef}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </Col>
          </Row>
          {showHistory
            ? renderHistory(
                inventoryItemLookupHistory,
                setInputValue,
                retrieveAndRender,
                setShowHistory,
                () => {
                  setInventoryItemLookupHistory([]);
                  setShowHistory(false);
                }
              )
            : null}
          {renderNavButtons(
            loadingInventoryItem,
            setInputValue,
            setErrorText,
            inputRef,
            setShowBarcodeScanner,
            setShowHistory,
            showBarcodeScanner,
            showHistory,
            inventoryItemLookupHistory.length > 0
          )}
        </Form>
      </Container>
      {errorText ? (
        <Row className="mt-2">
          <Col>
            <Alert
              onClose={() => setErrorText("")}
              dismissible
              variant="warning"
            >
              {errorText}
            </Alert>
          </Col>
        </Row>
      ) : null}
      {showBarcodeScanner ? (
        <Row>
          <Col>
            <BarcodeScanner onBarcodeDetected={onBarcodeDetected} />
          </Col>
        </Row>
      ) : null}
      {inventoryItem ? (
        <>
          <Row ref={inventoryItemContainerRef} className="mt-3">
            <Col lg={8} sm={12}>
              <h3>Inventory Item Information</h3>
              <InventoryItemSection
                inventoryItem={inventoryItem}
                loading={loadingInventoryItem}
                catalogItem={catalogItem}
                printLabelHtml={printLabelContentsHTML?.join("\n") ?? ""}
                persist={updateInventoryItem}
                isPersisting={isPersisting}
              />
            </Col>
            <Col sm={12} lg={4}>
              {inventoryItem ? (
                <Row>
                  <h3>Item Images</h3>
                  {!inventoryItem?.images ||
                  inventoryItem?.images.length === 0 ? (
                    <b style={{ color: darkMode ? "lightcoral" : "coral" }}>
                      No images found
                    </b>
                  ) : (
                    <InventoryImagesComponent
                      deleteImageFunction={(image) => {
                        return new Promise(() => {
                          inventoryWorker
                            .deleteImage(
                              userAuthenticatedContext.token!,
                              inventoryItem.id,
                              image.uniqueId
                            )
                            .then(() => {
                              // Set a timeout to allow the image to be deleted before refreshing
                              setTimeout(() => {
                                retrieveAndRender(inventoryItem.id);
                              }, 1000);
                            });
                        });
                      }}
                      images={inventoryItem?.images ?? []}
                    />
                  )}
                  <Button
                    variant="info"
                    style={{ marginTop: "0.5em" }}
                    onClick={() => {
                      setModalImageUploadShow(true);
                    }}
                  >
                    Upload Images
                  </Button>
                </Row>
              ) : (
                <Loading />
              )}
              <hr />
            </Col>
            <Col sm={12} lg={12}>
              <h3>Catalogue Information</h3>
              {catalogItem ? (
                <CatalogItemSection
                  catalogItem={catalogItem}
                  catalogItemId={inventoryItem?.catalogItemId}
                  loading={loadingCatalogItem}
                  inventoryItem={inventoryItem}
                />
              ) : (
                <b style={{ color: darkMode ? "lightcoral" : "coral" }}>
                  No or invalid catalogue entry assigned to item
                </b>
              )}
            </Col>
          </Row>
        </>
      ) : loadingInventoryItem ? (
        <Loading />
      ) : (
        <div style={{ textAlign: "center", marginTop: "2em" }}>
          <h3>Please enter or scan an item ID above</h3>
        </div>
      )}
      <Modal
        show={modalImageUploadShow}
        dialogClassName="image-upload-modal"
        onHide={() => {
          // eslint-disable-next-line no-restricted-globals
          confirm("Are you sure you want to close without saving?") &&
            setModalImageUploadShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageUploadComponent
            maxFiles={10}
            previewSizes={{
              xxl: "3",
              xl: "3",
              lg: "2",
              md: "1",
              sm: "1",
              xs: "1",
            }}
            onDrop={(files) => {
              setImagesToUpload(files);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={isUploadingImages}
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              confirm("Are you sure you want to close without saving?") &&
                setModalImageUploadShow(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="success"
            disabled={isUploadingImages}
            onClick={() => {
              setIsUploadingImages(true);
              const images: GenerateUploadImageUrlImage[] = imagesToUpload.map(
                (image) => ({
                  name: image.name,
                  imageSource: "upload",
                })
              );
              inventoryWorker
                .getPresignedUrlForImageUploads(
                  userAuthenticatedContext.token!,
                  {
                    inventoryId: inventoryItem!.id,
                    images: images,
                  }
                )
                .then((result) => {
                  Promise.all(
                    result.data.presignedUrls.map((presignedUrl, i) =>
                      axios.put(presignedUrl.url, imagesToUpload[i])
                    )
                  ).then(() => {
                    setModalImageUploadShow(false);
                    setIsUploadingImages(false);
                    retrieveAndRender(inventoryItem!.id);
                  });
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
          >
            Save Images
            {isUploadingImages ? <Spinner size="sm" /> : null}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const InventoryItemSection = ({
  loading,
  inventoryItem,
  catalogItem,
  printLabelHtml,
  persist,
  isPersisting,
}: {
  loading: boolean;
  inventoryItem?: InventoryItem;
  catalogItem?: CatalogItem;
  printLabelHtml: string;
  persist: (i: InventoryItemWithPatchableFields) => Promise<void>;
  isPersisting: boolean;
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  if (loading || !inventoryItem) {
    return <Loading />;
  }

  const closeEditModal = () => setShowEditModal(false);
  return (
    <>
      <Row>
        <Col style={{ display: "flex" }}>
          <Button
            className="me-2"
            variant="warning"
            onClick={() => setShowEditModal(true)}
          >
            Edit Item
          </Button>
          <PrintUI printContentsHTML={printLabelHtml} />
        </Col>
      </Row>
      <InventoryItemComponent
        inventoryItem={inventoryItem}
        viewOnly={true}
        persist={async (i) => {}}
        catalogItem={catalogItem}
        showGrading={true}
      />
      <Modal
        show={showEditModal}
        onHide={closeEditModal}
        dialogClassName="modal-90w"
        aria-labelledby="edit-inventory-item-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="edit-inventory-item-modal-title">
            Edit Inventory Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InventoryItemComponent
            inventoryItem={inventoryItem}
            viewOnly={false}
            persist={persist}
            catalogItem={catalogItem}
            showGrading={false}
            cancelAction={closeEditModal}
            isPersisting={isPersisting}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const CatalogItemSection = ({
  loading,
  catalogItemId,
  catalogItem,
  inventoryItem,
}: {
  loading: boolean;
  inventoryItem?: InventoryItem;
  catalogItemId?: string;
  catalogItem?: CatalogItem;
}) => {
  if (!inventoryItem && !catalogItem && !loading && !catalogItemId) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  if (!catalogItemId) {
    return (
      <Container>
        <h3>Item not identified</h3>
        <i>TODO: ability to lookup item</i>
      </Container>
    );
  }

  return catalogItem ? (
    <Container>
      <CatalogItemPreviewComponent width="100%" catalogItem={catalogItem} />
    </Container>
  ) : null;
};

const renderHistory = (
  inventoryItemLookupHistory: InventoryItemLookupHistoryItem[],
  setInputValue: React.Dispatch<React.SetStateAction<string>>,
  retrieveAndRender: (id: string) => void,
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>,
  clearHistory: () => void
) => (
  <Container className="mt-2">
    <Row>
      <Col>
        <h2>History</h2>
      </Col>
      <Col className="d-flex justify-content-end">
        <Button
          variant="warning"
          className="m-1 ml-auto"
          onClick={clearHistory}
        >
          Clear History
        </Button>
      </Col>
    </Row>
    <Row xs={2} md={3} lg={4}>
      {inventoryItemLookupHistory.map((item, i) => (
        <Col key={i}>
          <Button
            className="m-1"
            variant="light"
            onClick={() => {
              setInputValue(item.id);
              retrieveAndRender(item.id);
              setShowHistory(false);
            }}
          >
            {item.id}
            <br />
            <span style={{ color: "gray" }}>
              {new Date(item.timestamp).toLocaleTimeString()}
              {" - "}
              {new Date(item.timestamp).toLocaleDateString()}
            </span>
          </Button>
        </Col>
      ))}
    </Row>
    <hr />
  </Container>
);

const renderNavButtons = (
  loadingInventoryItem: boolean,
  setInputValue: React.Dispatch<React.SetStateAction<string>>,
  setErrorText: React.Dispatch<React.SetStateAction<string>>,
  inputRef: React.RefObject<HTMLInputElement>,
  setShowBarcodeScanner: React.Dispatch<React.SetStateAction<boolean>>,
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>,
  showBarcodeScanner: boolean,
  showHistory: boolean,
  hasHistory: boolean
) => (
  <Row xs={2} md={2} lg={4}>
    <Col>
      <Button
        variant="warning"
        size="lg"
        disabled={loadingInventoryItem}
        className="mt-2 w-100"
        onClick={() => {
          setInputValue("");
          setErrorText("");
          inputRef.current?.focus();
        }}
      >
        Clear
      </Button>
    </Col>
    <Col>
      <Button
        variant="info"
        size="lg"
        disabled={loadingInventoryItem}
        className="mt-2 w-100"
        onClick={() => {
          setShowBarcodeScanner(!showBarcodeScanner);
        }}
      >
        <UpcScan />
      </Button>
    </Col>
    <Col>
      <Button
        size="lg"
        disabled={!hasHistory}
        className="mt-2 w-100"
        onClick={() => setShowHistory(!showHistory)}
      >
        History
        <HourglassBottom />
      </Button>
    </Col>
    <Col>
      <Button
        variant="success"
        size="lg"
        type="submit"
        className="mt-2 w-100"
        disabled={loadingInventoryItem}
      >
        Submit
      </Button>
    </Col>
  </Row>
);

export default withAuthenticationRequired(InventoryScanPage, {
  onRedirecting: () => <Loading />,
});
