import { v4 as uuidv4 } from "uuid";
import "./custom-toggle.scss";
import { useCircleDarkMode } from "../../hooks/useCircleDarkMode";
import React from "react";

type CustomToggleProps = {
  checked: boolean;
  onChange: () => void;
  switchId?: string;
  icons: [React.ReactNode, React.ReactNode];
  ariaLabel?: string;
};

const CustomToggle: React.FC<CustomToggleProps> = ({
  checked,
  onChange,
  switchId,
  icons,
  ariaLabel,
}) => {
  const uniqueId = React.useMemo(() => switchId || uuidv4(), [switchId]);

  const darkMode = useCircleDarkMode();

  return (
    <label
      htmlFor={uniqueId}
      className={`custom-switch-container me-2 ${darkMode ? "dark-mode" : ""}`}
    >
      <input
        type="checkbox"
        id={uniqueId}
        checked={checked}
        onChange={onChange}
        className="custom-switch"
        aria-label={ariaLabel}
      />
      <div className="custom-switch-icons">
        {icons.map((Icon, index) => (
          <span
            key={index}
            className={`custom-switch-icon ${
              checked === (index === 1) ? "active" : ""
            }`}
          >
            {Icon}
          </span>
        ))}
      </div>
    </label>
  );
};

export default CustomToggle;
