import React, { useState, useCallback, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { createWorkerFactory } from "@shopify/react-web-worker";
import BarcodeScanner from "../barcode-scanner";
import { CameraFill, Search } from "react-bootstrap-icons"; // Import the search icon
import { useCircleDarkMode } from "../../hooks/useCircleDarkMode";
import { useDebugMode } from "../../hooks/useDebugMode";

const createLocationWorker = createWorkerFactory(
  () => import("../../workers/location")
);

interface BarcodeResult {
  getText: () => string;
}

export const ItemLocationScanner = () => {
  const [showBarcodeScanner, setShowBarcodeScanner] = useState<boolean>(false);
  const [itemValue, setItemValue] = useState<string>("");
  const [locationValue, setLocationValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState<string>();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isScanned, setIsScanned] = useState<boolean>(false); // Flag to check if input is scanned
  const [itemConfirmed, setItemConfirmed] = useState<boolean>(false); // Flag to check if item input is confirmed
  const [confirmedItem, setConfirmedItem] = useState<string>();

  const darkMode = useCircleDarkMode();
  const debugMode = useDebugMode();

  if (debugMode) {
    document.addEventListener("keydown", function (e) {
      console.table({
        keyCodeDEP: e.which,
        key: e.key,
        code: e.code,
        location: e.location,
      });
    });
  }

  const locationWorker = createLocationWorker();
  const navigate = useNavigate();
  const itemInputRef = useRef<HTMLInputElement>(null);
  const locationInputRef = useRef<HTMLInputElement>(null);

  const onCameraBarcodeDetected = useCallback(
    (barcode: BarcodeResult) => {
      setShowBarcodeScanner(false);
      const v = barcode.getText();
      if (itemConfirmed) {
        setLocationValue(v);
        setIsScanned(true);
        // Submit the form after setting the location value if scanned
        if (locationValue) {
          document
            .getElementById("location-form")
            ?.dispatchEvent(
              new Event("submit", { cancelable: true, bubbles: true })
            );
        }
      } else {
        // It is the item scan
        setItemValue(v);
        setItemConfirmed(true);
        setConfirmedItem(v);
        locationInputRef.current?.focus();
      }
    },
    [itemConfirmed, locationValue]
  );

  const storeValues = async (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    if (!itemValue || !locationValue) {
      setShowErrorModal(true);
      setSuccess(undefined);
      return;
    }

    setIsLoading(true);
    setSuccess(undefined);

    try {
      await locationWorker.storeItemsLocation([itemValue], locationValue);
      setItemValue("");
      setLocationValue("");
      setItemConfirmed(false);
      setSuccess("Values stored successfully.");
      itemInputRef.current?.focus();

      // Clear the success message after 2 seconds
      setTimeout(() => {
        setSuccess(undefined);
      }, 5000);
    } catch (err) {
      console.error("Error storing values:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(undefined);
      }, 5000);
      return () => clearTimeout(timer);
    }
    return undefined; // to avoid es-lint warning, explicitly return undefined
  }, [success]);

  const iconWidthAndHeight = 48;

  const iconStyles = {
    width: iconWidthAndHeight,
    height: iconWidthAndHeight,
    style: { cursor: "pointer" },
  };

  return (
    <Container>
      <Row>
        <Col>
          <h3>Location Scanner</h3>
        </Col>
        <Col className="d-flex justify-content-center">
          <CameraFill
            {...iconStyles}
            color={showBarcodeScanner ? "blue" : darkMode ? "white" : "black"}
            onClick={() => setShowBarcodeScanner(!showBarcodeScanner)}
          />
        </Col>
        <Col className="d-flex justify-content-center">
          <Search
            {...iconStyles}
            onClick={() => navigate("/item-location-search")}
          />
        </Col>
      </Row>
      <Form
        id="location-form"
        onSubmit={(e) => {
          if (itemValue && !locationValue) {
            console.log({
              itemValue,
              locationValue,
              itemConfirmed,
              isScanned,
            });
            setItemConfirmed(true);
            setConfirmedItem(itemValue);
            e.preventDefault();
            e.stopPropagation();
            return false;
          }
          storeValues(e);
          return true;
        }}
      >
        {showBarcodeScanner ? (
          <Row>
            <Col>
              <BarcodeScanner onBarcodeDetected={onCameraBarcodeDetected} />
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <b>Item</b>
            <Form.Control
              style={{
                fontSize: "1.5em",
                fontFamily: "Menlo, monospace, sans-serif",
                textAlign: "center",
                maxWidth: "800px",
              }}
              autoFocus
              type="text"
              name="type"
              ref={itemInputRef}
              placeholder="PMID or Pallet"
              value={itemValue}
              onChange={(e) => {
                setItemValue(e.target.value);
                setIsScanned(false);
                setItemConfirmed(false);
              }}
            />
            {!itemConfirmed || confirmedItem !== itemValue ? (
              <Button
                variant="primary"
                size="lg"
                className="mt-2"
                onClick={() => {
                  setItemConfirmed(true);
                  setConfirmedItem(itemValue);
                }}
                disabled={!itemValue}
                style={{
                  fontFamily: "Menlo, monospace, sans-serif",
                  textAlign: "center",
                  marginBottom: "0.5em",
                  maxWidth: "800px",
                }}
              >
                Confirm Item
              </Button>
            ) : null}
          </Col>
        </Row>

        {itemConfirmed ? (
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <b>Location</b>
              <Form.Control
                style={{
                  fontSize: "1.5em",
                  fontFamily: "Menlo, monospace, sans-serif",
                  textAlign: "center",
                  maxWidth: "800px",
                }}
                autoFocus
                type="text"
                name="type"
                ref={locationInputRef}
                placeholder="Pallet or Warehouse Location"
                value={locationValue}
                onChange={(e) => {
                  setLocationValue(e.target.value);
                  setIsScanned(false);
                }}
              />
            </Col>
          </Row>
        ) : null}
        <br />
        {itemValue && locationValue ? (
          <>
            <Row>
              <Col>
                Store item <br />
                <b>{itemValue}</b>
                <br /> in location <br />
                <b>{locationValue}</b>?
              </Col>
              <Col
                style={{
                  fontSize: "1.5em",
                  fontFamily: "Menlo, monospace, sans-serif",
                  textAlign: "center",
                  marginTop: "1em",
                  maxWidth: "800px",
                }}
              >
                <Button type="submit" size="lg" disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Storing...
                    </>
                  ) : (
                    "Confirm Location"
                  )}
                </Button>
              </Col>
            </Row>
          </>
        ) : null}
      </Form>
      {success && (
        <Row>
          <Col>
            <Alert variant="success" className="mt-3">
              {success}
            </Alert>
          </Col>
        </Row>
      )}
      {showErrorModal && (
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>Item value and location value are required.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowErrorModal(false)}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default ItemLocationScanner;
